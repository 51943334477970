.smooth-dnd-container {
    padding-top: 10px !important;
}
.layer-box {
    min-height: 40px;
    margin-bottom: 2px;
    cursor: pointer;
    width: 98% !important;
    margin-left: 2% !important;
    border: none !important;

    div {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border: none !important;
    }

    .cb {clear: both;}
    .layer-group {
        width: 100%;
    }

    .layer-group .layer-group-text {
        float: left;
        display: table;
        height:40px;
        width: 74%;
        padding-left: 2%;
    }
    .layer-group .layer-group-text span {
        vertical-align:middle;
        display: table-cell;
    }

    .layer-group .layer-group-action {
        width: 25%;
        float: left;
        min-height:40px;
        padding-top: 7px;
        text-align: right;
        padding-left: 10px !important;
    }

    .layer-group .layer-group-action .MuiIconButton-root {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .layer-item {
        width: 100%;
    }
    .layer-item .layer-item-check {
        width: 8%;
        float: left;
        display: table;
        height:20px;
        padding-top: 6px;
        text-align: center;
    }
    .layer-item .layer-item-check input {
        vertical-align:middle;
        display: table-cell;
    }

    .layer-item .layer-item-text {
        float: left;
        display: table;
        height:40px;
        padding-left: 5px;
        width: 76% !important;
    }
    .layer-item .layer-item-text span {
        vertical-align:middle;
        display: table-cell;
    }


    .layer-item .layer-item-action {
        width: 50px;
        float: right;
        padding-top: 6px;
        text-align: right;
    }

    .layer-item .layer-item-action .MuiIconButton-root {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .MuiButtonBase-root {padding: 0px !important;}
    .MuiPaper-elevation1 {
        box-shadow: none !important;
        border-top: solid 1px #CCCCCC !important;
    }
}
.MuiAccordionSummary-root {padding: 0px !important;}
.MuiAccordionDetails-root {padding: 0px !important;}


.left-menu-layer-control-container {
    details {
        summary {
            background-color: black;
        }
    }

    .base-map-header-summary {

        .MuiAccordionSummary-expandIcon {
            position: absolute;
            left: 0px
        }

        .add-button-icon {
            right: 14px;
        }
    }

    .smooth-dnd-container.vertical {
        width: 100%;
        min-height: auto;
        min-width: auto;
    }

    .icon-layer {
        color: green;
    }

    .icon-layers-custom {
        left: 0px;
    }

    input[type=checkbox] {
        cursor: pointer;
    }

    .MuiAccordionSummary-root {
        &.Mui-expanded {
            min-height: 40px;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;

        &.Mui-expanded {
            margin: 10px 0 0 0;
        }
    }
}