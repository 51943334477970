.react-select-container {
  box-shadow: none !important;
  border: none !important;
  &.first-load {
    .react-select__menu {
      display: none !important;
    }
  }

  .react-select__control {
    border: none !important;
    .react-select__value-container {
      padding-left: 15px;
      cursor: text;

      .react-select__placeholder {
        font-size: 14px;
      }

      .react-select__single-value,
      .react-select__input {
        font-size: 14px;
      }
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        width: 0;
        margin: 0;
      }

      .react-select__indicator {
        &.react-select__dropdown-indicator {
          display: none;
        }

        &.react-select__clear-indicator {
          margin-right: 0px;
        }

        color: #ffffff;
        padding: 0px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.home-page {
  .home-search-box {
    position: absolute;
    width: 100%;
  }
  .home-search-container {
    width: 99%;
    margin: auto;
    height: 50px;
  }
  .home-search-border {
    width: 86%;
    margin: 190px auto 0px auto;
    background-color: #FFFFFF;
    padding: 20px;
    border: solid 1px #CCCCCC;
    border-radius: 5px;
    -moz-box-shadow: 1px 2px 4px rgba(10, 0, 0,0.5);
    -webkit-box-shadow: 1px 2px 4px rgba(10, 0, 0, .5);
    box-shadow: 1px 2px 4px rgba(10, 0, 0, .5);

  }
  .home-search-action-box {
    height: 20px;
  }
  .home-search-action-box a {
    font-weight: bold;
    color: #FFFFFF;
    margin-right: 20px;
  }

  .home-search-action-box .search-action-planning {
    width: 80px;
    color: #207CCA;
    float: left;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
  }

  .home-search-action-box .search-action-place {
    width: 70px;
    color: #207CCA;
    float: left;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
  }

  .home-search-action-box .search-action-active {
    border-bottom: solid 2px #207CCA;
  }

  .search-option-box {
    width: 20%;
    height: 50px;
    text-align: left !important;
    float: left;
  }

  .search-option-box select {
    border: solid 1px #CCCCCC;
    width: 100%;
    padding: 0px 5% 0px 5%;
    height: 50px;
    font-size: 16px;
  }
  .search-option-box select option {
    height: 50px;
  }

  .search-box-planning {
    width: 100%;
    height: 50px;
    text-align: left !important;
    float: left !important;
  }

  .search-box-planning .search-planning-input {
    width: 70%;
    float: left;
    height: 50px;
    padding-top: 7px;
    background-color: #FFFFFF;
    border: solid 1px #CCCCCC;
  }

  .search-box-planning .search-planning-input div {
    border: none !important;
    box-shadow: none !important;
  }

  .search-box-planning .search-planning-button {
    width: 10%;
    float: left;
    min-height: 51px;
    padding: 14px 7px 7px 7px;
    background-color: #247FCB;
    color: #FFFFFF;
    cursor: pointer;
    border: solid 1px #CCCCCC;
    text-align: center;
    font-weight: bold;
  }

  .search-box-place {
    width: 100%;
    float: left;
    height: 50px;
    background-color: #FFFFFF;
  }

  .search-box-place .search-place-input {
    width: 70%;
    float: left;
    height: 50px;
    padding-top: 6px;
    background-color: #FFFFFF;
    border: solid 1px #CCCCCC;
  }

  .search-box-place .search-place-input div {
    border: none !important;
    box-shadow: none !important;
  }
  .home-page .home-search .input-search {
    border: none !important;
    border-radius: 0px !important
  }
  .search-box-place .search-place-button {
    width: 10%;
    float: left;
    min-height: 51px;
    padding: 14px 7px 7px 7px;
    background-color: #247FCB;
    color: #FFFFFF;
    cursor: pointer;
    border: solid 1px #CCCCCC;
    text-align: center;
    font-weight: bold;
  }

  .home-search-button {
    background-color: #247FCB !important;
    color: #FFFFFF !important;
    cursor: pointer;
  }

  .home-search {
    position: relative;
    top: 85px;
    margin: auto;
    width: 100% !important;
    text-align: center;

    .home-title {
      color: #DB353A;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 40px;
      -webkit-text-stroke: 1px navy;
      -webkit-text-stroke-color: #FFFFFF;
      font-family: Tahoma;
    }

    .search-title {
      height: 40px;
      width: 50%;
      margin: auto;
      text-align: left;
    }

    .search-title a {
      color: #FFFFFF;
      margin-right: 10px;
      font-weight: bold;
      font-size: 18px;
    }

    .home-search-action-box {
      height: 40px;
      width: 60%;
      margin: auto;
      text-align: left;
    }


    .input-search {
      width: 100%;
      border: 1px solid hsl(0, 0%, 80%);
      color: #000;
      border-radius: 24px;
      min-height: 38px;
      padding: 0px 15px;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a4a4a4;
      }
    }

    .input-autosuggest {
      width: 93%;
      border: 1px solid hsl(0, 0%, 80%);
      color: #000;
      border-radius: 24px;
      min-height: 38px;
      padding: 0px 15px;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a4a4a4;
      }
    }

    .search-input-planning {
      position: relative;

      .list-group {
        margin-left: 36px;

        .list-group-item {
          margin-top: 8px;
          padding: 0.3rem 1rem;
          position: absolute;
          top: 31px;
          z-index: 1;
          width: 88%;
        }
      }
    }
  }

  .slider-home-page {
    .slick-slider {
      .slick-dots {
        bottom: 30px;

        li {
          width: 10px;
          height: 10px;
          margin: 3px;

          button {
            width: 12px;
            height: 12px;
            background-color: rgba(0, 0, 0, 0);
            border: 1px solid #fff;
            border-radius: 50%;
            padding: 4px;
          }

          button::before {
            font-size: 12px;
            width: 12px;
            height: 12px;
            opacity: 0;
            color: #fff;
            line-height: 15px;
          }

          &.slick-active {
            button::before {
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .home-map {
    position: relative;

    &:focus {
      outline: none;
    }

    img {
      width: 100%;
      height: 22.5rem;
      object-fit: cover;
    }

    .map-content {
      position: absolute;
      top: 0;
      right: 300px;
      width: 23rem;
      height: 100%;
      background: linear-gradient(0deg, #1075bd, #1075bd);

      .title {
        font-weight: bold;
        font-size: 1.125rem;
        text-align: center;
        color: #ffffff;
      }

      hr {
        border-color: #ffffff;
      }

      .content {
        font-weight: normal;
        font-size: 1rem;
        text-align: justify;
        color: #ffffff;
      }
    }
  }

  .home-content {

    .title {
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      color: #333333;
      margin-bottom: 4px;
      margin-top: 25px;
    }

    hr {
      border-width: 2px;
      border-color: #ffce38;
      width: 35%;
      margin-top: 0;
    }

    .content {
      .item {
        margin-bottom: 1rem;
        margin-top: 10px;

        span {
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          bottom: 10px;
          left: 0;
          white-space: nowrap;
          font-size: 20px;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }

  .react-autosuggest__input {
    width: 90%;
    border-style: none;
    position: absolute;
  }

  .map__search-bar-container {
    border: none !important;
    .map__map-maker-icon {
      color: #fd6c6c;
    }

    .map__github-icon {
      font-size: 24px;
    }

    .map__github-link {
      color: #262626;
      font-size: 20px;
    }

    .map__github-link:hover,
    .map__github-link:active {
      color: #4078c0;
      text-decoration: none;
    }

    .map__suggestion-icon {
      margin-right: 8px;
    }

    .map__search-input-container {
      position: relative;
    }

    .map__search-input-container input {
      border: none !important;
    }

    .map__clear-button,
    .map__clear-button:active,
    .map__clear-button:focus {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      outline: none;
      font-weight: 600;
      color: #999;
    }

    .map__autocomplete-container {
      border-bottom: honeydew;
      border-left: honeydew;
      border-right: honeydew;
      border-top: 1px solid #e6e6e6;
      border-radius: 0 0 2px 2px;
      position: absolute;
      z-index: 1;
      width: 95%;
    }

    .map__suggestion-item {
      padding: 8px;
      text-align: left;
      background-color: #fff;
      cursor: pointer;
      border-bottom: 1px solid #e6e6e6;
    }

    .map__suggestion-item--location-pointer {
      width: 16px;
      height: 16px;
    }

    .map__suggestion-item--active {
      background-color: #fafafa;
    }

    .map__dropdown-footer {
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }

    .map__dropdown-footer-image {
      display: inline-block;
      width: 150px;
    }

    .map__spinner {
      color: #18bc9c;
      font-size: 30px;
    }

    .map__error-message {
      color: red;
    }

    .map__geocode-result-header {
      font-size: 20px;
      color: #222222;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .home-page {
    .home-map {
      .map-content {
        right: 215px;
      }
    }
  }
}